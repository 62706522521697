<template>
  <div class="um-trending">
    <tz-banner
      class="tz-banner--small"
      :title="pageTitle"
      :has-overlay="true"
      :is-light="false"
      :isPageTitle="true"
      :image="cms.page.header_image"
    />
    <div class="um-trending__container sh-wysiwyg">
      <tz-breadcrumbs :items="breadcrumbs" :site-url="siteUrl" />
      <TzMarkDown class="wl-calendar__text" :content="cms.page.body" />
      <h2
        class="um-trending__dropdown-title"
        v-text="cms.page.calendar.dropdown_title"
      />
      <div class="um-trending__dropdown-container">
        <button
          class="um-trending__dropdown"
          :class="{ 'um-trending__dropdown--open': isDropdownOpen }"
          @click="handleDropdown"
          v-text="year"
        />
        <ul
          class="um-trending__previous-years"
          :class="{ 'um-trending__previous-years--show': isDropdownOpen }"
        >
          <li
            class="um-trending__previous-years-item"
            v-for="previousYear in previousYears"
            :key="previousYear"
          >
            <a
              class="um-trending__previous-years-link"
              :class="{
                'um-trending__previous-years-link--active':
                  parseInt(previousYear) === parseInt(year)
              }"
              :href="
                previousYear === currentYear
                  ? '/trending-name-search/'
                  : `/trending-name-search/${previousYear}/`
              "
              v-text="previousYear"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="um-trending__section">
      <div class="um-trending__container">
        <h2 class="um-trending__title" v-text="cms.page.calendar.title" />
        <div class="um-calendar">
          <div class="um-calendar__month" v-for="(month, index) in months">
            <h3 class="um-calendar__title">{{ month }} {{ year }}</h3>
            <div class="um-calendar__body">
              <strong class="um-calendar__weekday">Su</strong>
              <strong class="um-calendar__weekday">Mo</strong>
              <strong class="um-calendar__weekday">Tu</strong>
              <strong class="um-calendar__weekday">We</strong>
              <strong class="um-calendar__weekday">Th</strong>
              <strong class="um-calendar__weekday">Fr</strong>
              <strong class="um-calendar__weekday">Sa</strong>
              <component
                :key="`${year}_${month}_${index}_${Math.random() * year}`"
                :href="url"
                v-bind:is="url ? 'a' : 'span'"
                :role="url ? 'link' : false"
                v-for="{ number, url, isFuture } in getDays(index)"
                class="um-calendar__day"
                :class="{
                  'um-calendar__day--future': isFuture
                }"
                v-text="number"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const pageCms = require(`@/assets/cms/pages/popular-name-calendar.json`);
import TzBanner from '@trazi/tz-banner/src/tz-banner.vue';
import TzMarkDown from '@trazi/tz-markdown/src/tz-markdown.vue';
import TzBreadcrumbs from '@trazi/tz-breadcrumbs/src/tz-breadcrumbs.vue';
const NBars = require('nbars/commonjs.js');
import breadcrumbsHelper from '@/assets/js/breadcrumbsHelper';

export default {
  name: 'popular-name-calendar',
  components: {
    TzBanner,
    TzMarkDown,
    TzBreadcrumbs
  },
  data() {
    return {
      year: this.$route.params.year || new Date().getFullYear(),
      previousYears: [],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      isDropdownOpen: false,
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    siteUrl() {
      return `https://unmask.com`;
    },
    breadcrumbs() {
      return breadcrumbsHelper.setTrendingNamesBreadcrumb('', this.$route.path);
    },
    yesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    },
    pageTitle() {
      return NBars.transform(this.cms.page.title, {
        year: this.year
      });
    }
  },
  methods: {
    getDays(month) {
      const properMonth = month + 1;
      const date = new Date(this.year, month, 1);
      const dayOfWeek = date.getDay();
      const lastDay = new Date(date);
      lastDay.setFullYear(date.getFullYear(), date.getMonth() + 1, 0);
      const days = [];
      date.setDate(date.getDate() - dayOfWeek);

      while (date <= lastDay) {
        const number = date.getDate();
        const day = {
          number
        };
        if (date.getMonth() + 1 === properMonth) {
          if (date >= this.yesterday) {
            day['isFuture'] = true;
          } else {
            day[
              'url'
            ] = `/trending-name-search/${this.year}-${properMonth}-${number}/`;
          }
        }
        days.push(day);
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getPreviousYears() {
      const maxYear = new Date().getFullYear();
      const minYear = 2012;
      const years = [];

      for (let i = maxYear; i >= minYear; i--) {
        years.push(i);
      }

      this.previousYears = [...years];
    },
    handleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  },
  created() {
    this.getPreviousYears();
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms
        };
      }
    }
  },
  head() {
    const transformedMeta = [];
    const pageTitle = NBars.transform(this.cms.page.page_title, {
      year: this.year
    });

    for (let i = 0; i < this.cms.page.meta_root.length; i++) {
      const metaCopy = Object.assign({}, this.cms.page.meta_root[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = pageTitle;
      }

      transformedMeta[i] = metaCopy;
    }

    return {
      title: pageTitle,
      meta: transformedMeta
    };
  },
  middleware: 'shared/trending-name-redirect'
};
</script>
