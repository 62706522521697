const initialResultsBreadcrumb = {
  href: '/',
  text: 'Free People Search'
};

/**
 * Return a single breadcrumb
 *
 * @param {string} text
 * @param {boolean} disabled
 * @param {string} href
 * @param {string} emoji
 * @param {string} schemaName
 * @returns {{disabled: *, text: *, href: *, emoji: *, schemaName: *}}
 */
const getBreadcrumb = function(opts = {}) {
  return opts;
};

/**
 * Shortens breadcrumb schema based of max charters
 *
 * @param {object} opts
 * @param {string} opts.crumb
 * @param {number} opts.maxChars
 * @param {string} opts.abbrev
 * @returns {string|*}
 */
const shortenBreadcrumb = function(opts = {}) {
  const splitName = opts.crumb.split(' ');
  const firstName = splitName[0];
  const lastName = splitName[1];
  const extraStates = ['Oregon', 'Nevada'];

  // Prioritize them Abbreviations
  if (
    (opts.abbrev && firstName.length >= opts.maxChars) ||
    (opts.abbrev && extraStates.includes(firstName))
  ) {
    return opts.abbrev;
  }

  if (firstName.length >= opts.maxChars) {
    // Show whole name or cut it
    return opts.crumb.substring(0, opts.maxChars);
  }

  if (!lastName) {
    return opts.crumb;
  }

  // Shorter Full Name Logic
  const lastNameLetter = lastName.substring(0, 1);

  switch (firstName.length) {
    case opts.maxChars - 1: {
      return `${firstName}${lastNameLetter}`;
    }
    case opts.maxChars - 2: {
      return `${firstName}-${lastNameLetter}`;
    }
    case opts.maxChars - 3: {
      return `${firstName}-${lastName.substring(0, 2)}`;
    }
    case opts.maxChars - 4: {
      return `${firstName}-${lastName.substring(0, 3)}`;
    }
    case opts.maxChars - 5: {
      return `${firstName}-${lastName.substring(0, 4)}`;
    }
    case opts.maxChars - 6: {
      return `${firstName}-${lastName.substring(0, 5)}`;
    }
  }
};

const breadcrumbsHelper = {
  getFormattedPageNumber: page => {
    return page ? `/${page}/` : '';
  },
  /**
   * Return a formatted results breadcrumb list
   *
   * @param {object} opts
   * @param {string} formattedFirstName
   * @param {string} formattedLastName
   * @param {string} rawFirstName
   * @param {string} rawLastName
   * @param {string} indexPage
   * @param {string} pageNumber
   * @param {string|null} formattedState
   * @param {string|null} stateLink
   * @param {string|null} formattedCity
   * @param {string|null} cityLink
   * @param {boolean} isProfile
   * @returns {[{href: string, text: string}]}
   */
  setResultsCrumbs: opts => {
    const hasState = opts.stateLink !== undefined;
    const hasCity = opts.cityLink !== undefined;
    const formattedName = `${opts.formattedFirstName} ${opts.formattedLastName}`;
    let nameLink = `/${opts.rawFirstName}-${opts.rawLastName}/`;
    let crumbs = [initialResultsBreadcrumb];

    // Add Letter Index Link
    const lastnameLetter = opts.rawLastName.charAt(0);
    crumbs.push(
      getBreadcrumb({
        text: lastnameLetter,
        disabled: false,
        href: `/people/${lastnameLetter}000000/`,
        emoji: '&#X1F5C4;'
      })
    );

    // Add Last Name Index Link
    crumbs.push(
      getBreadcrumb({
        text: opts.formattedLastName,
        disabled: false,
        href: `/people/${opts.indexPage}/`,
        emoji: '&#X1F575;',
        schemaName: shortenBreadcrumb({
          crumb: opts.formattedLastName,
          maxChars: 7
        })
      })
    );

    // Add SEO Root Link
    crumbs.push(
      getBreadcrumb({
        text: formattedName,
        disabled: false,
        href: nameLink,
        emoji: '&#X1F3AD;',
        schemaName: shortenBreadcrumb({ crumb: formattedName, maxChars: 7 })
      })
    );

    if (hasState) {
      let fullStateLink = `${nameLink}${opts.stateLink}/`;
      crumbs.push(
        getBreadcrumb({
          text: opts.formattedState,
          disabled: false,
          href: fullStateLink,
          emoji: '&#127482;&#127480;',
          schemaName: shortenBreadcrumb({
            crumb: opts.formattedState,
            maxChars: 7,
            abbrev: opts.stateLink
          })
        })
      );
    }

    if (hasCity) {
      crumbs.push(
        getBreadcrumb({
          text: opts.formattedCity,
          disabled: false,
          href: `${nameLink}${opts.stateLink}-${opts.cityLink}/`,
          emoji: '&#X1F3D9;'
        })
      );
    }

    if (opts.pageNumber) {
      const numberDisplay = `(Page ${opts.pageNumber.replace('/', '')})`;
      crumbs.push(
        getBreadcrumb({
          text: numberDisplay,
          disabled: false,
          href: `${nameLink}${opts.stateLink}-${opts.cityLink}${opts.pageNumber}/`
        })
      );
    }

    if (opts.isProfile) {
      crumbs.push(
        getBreadcrumb({
          text: `${formattedName} - Profile`,
          disabled: false,
          href: nameLink
        })
      );
    }

    // Last breadcrumb should be disabled
    crumbs[crumbs.length - 1].disabled = true;
    const secondToLast = crumbs[crumbs.length - 2];

    // Second to last crumb should be the only one that should be cut off from the schema
    for (let i = 0; crumbs.length > i; i++) {
      if (i !== crumbs.length - 2) {
        delete crumbs[i].schemaName;
      }
    }

    return crumbs;
  },
  /**
   * Generically sets a single breadcrumb
   * @param {string} opts.text
   * @param {string} opts.path
   * @param {number} opts.pageNumber
   * @param {string} opts.page
   * @param {string} opts.state
   * @returns {{href: string, text: string}[]}
   */
  setBreadcrumb: (opts = {}) => {
    let crumbs = [initialResultsBreadcrumb];

    if (opts.page === 'phone') {
      crumbs.push({
        href: '/phone/',
        text: 'Reverse Phone Lookup',
        emoji: '&#128241;'
      });
    }

    if (opts.page === 'address') {
      crumbs.push(
        {
          href: `/most-popular-last-names/state/${opts.state}/`,
          text: opts.state
        },
        {
          href: '/address/',
          text: 'Address',
          emoji: '&#127968;'
        }
      );
    }

    crumbs.push(
      getBreadcrumb({ text: opts.text, disabled: false, href: opts.path })
    );

    if (opts.pageNumber) {
      crumbs.push(
        getBreadcrumb({
          text: `(Page ${opts.pageNumber})`,
          disabled: false,
          href: `${opts.path}/`
        })
      );
    }

    // Last breadcrumb should be disabled
    crumbs[crumbs.length - 1].disabled = true;

    return crumbs;
  },
  /**
   * @param {string} date
   * @param {string} path
   */
  setTrendingNamesBreadcrumb: (date, path) => {
    const trendingNamesPath = '/trending-name-search/';
    const crumbs = [initialResultsBreadcrumb];

    crumbs.push(
      getBreadcrumb({
        text: 'Trending Name Search',
        disabled: !date,
        href: trendingNamesPath
      })
    );

    if (date) {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'long' });
      const day = d.getDate();
      const year = d.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;

      crumbs.push(
        getBreadcrumb({ text: formattedDate, disabled: true, href: path })
      );
    }

    return crumbs;
  },
  /**
   * Return a formatted results breadcrumb list for top full names pages
   * @param {boolean} includeAlphabet
   * @param {string|null} letter
   * @param {boolean} includeStates
   * @param {string|null} state
   * @returns {[{href: string, text: string}]}
   **/
  getTopLastNamesBreadcrumbs: (
    includeAlphabet,
    letter,
    includeStates,
    state
  ) => {
    const rootPath = '/most-popular-last-names/';
    const rootDisabled =
      !includeAlphabet && !letter && !includeStates && !state;
    const rootCrumb = getBreadcrumb({
      text: 'Most Popular Last Names',
      disabled: rootDisabled,
      href: rootPath
    });
    const crumbs = [initialResultsBreadcrumb, rootCrumb];
    if (includeAlphabet) {
      crumbs.push(
        getBreadcrumb({
          text: 'By Letter',
          disabled: !letter,
          href: `${rootPath}letter/`
        })
      );
    }
    if (letter) {
      crumbs.push(
        getBreadcrumb({
          text: letter,
          disabled: true,
          href: `${rootPath}letter/${letter}/`
        })
      );
    }
    if (includeStates) {
      crumbs.push(
        getBreadcrumb({
          text: 'By State',
          disabled: !state,
          href: `${rootPath}state/`
        })
      );
    }
    if (state) {
      crumbs.push(
        getBreadcrumb({
          text: state,
          disabled: true,
          href: `${rootPath}state/${state}`
        })
      );
    }
    return crumbs;
  },
  /** Return a formatted results breadcrumb list for top full names pages
   * @param {string|null} state
   * @returns {[{href: string, text: string}]}
   **/
  getTopFullNamesBreadcrumbs: state => {
    const rootPath = '/most-popular-full-names/';
    const rootCrumb = getBreadcrumb({
      text: 'Most Popular Full Names',
      disabled: !state,
      href: rootPath
    });
    const crumbs = [initialResultsBreadcrumb, rootCrumb];
    if (state) {
      crumbs.push(
        getBreadcrumb({
          text: state,
          disabled: true,
          href: `${rootPath}${state}`
        })
      );
    }

    return crumbs;
  }
};

export default breadcrumbsHelper;
