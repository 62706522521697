var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"um-trending"},[_c('tz-banner',{staticClass:"tz-banner--small",attrs:{"title":_vm.pageTitle,"has-overlay":true,"is-light":false,"isPageTitle":true,"image":_vm.cms.page.header_image}}),_vm._v(" "),_c('div',{staticClass:"um-trending__container sh-wysiwyg"},[_c('tz-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"site-url":_vm.siteUrl}}),_vm._v(" "),_c('TzMarkDown',{staticClass:"wl-calendar__text",attrs:{"content":_vm.cms.page.body}}),_vm._v(" "),_c('h2',{staticClass:"um-trending__dropdown-title",domProps:{"textContent":_vm._s(_vm.cms.page.calendar.dropdown_title)}}),_vm._v(" "),_c('div',{staticClass:"um-trending__dropdown-container"},[_c('button',{staticClass:"um-trending__dropdown",class:{ 'um-trending__dropdown--open': _vm.isDropdownOpen },domProps:{"textContent":_vm._s(_vm.year)},on:{"click":_vm.handleDropdown}}),_vm._v(" "),_c('ul',{staticClass:"um-trending__previous-years",class:{ 'um-trending__previous-years--show': _vm.isDropdownOpen }},_vm._l((_vm.previousYears),function(previousYear){return _c('li',{key:previousYear,staticClass:"um-trending__previous-years-item"},[_c('a',{staticClass:"um-trending__previous-years-link",class:{
              'um-trending__previous-years-link--active':
                parseInt(previousYear) === parseInt(_vm.year)
            },attrs:{"href":previousYear === _vm.currentYear
                ? '/trending-name-search/'
                : `/trending-name-search/${previousYear}/`},domProps:{"textContent":_vm._s(previousYear)}})])}),0)])],1),_vm._v(" "),_c('div',{staticClass:"um-trending__section"},[_c('div',{staticClass:"um-trending__container"},[_c('h2',{staticClass:"um-trending__title",domProps:{"textContent":_vm._s(_vm.cms.page.calendar.title)}}),_vm._v(" "),_c('div',{staticClass:"um-calendar"},_vm._l((_vm.months),function(month,index){return _c('div',{staticClass:"um-calendar__month"},[_c('h3',{staticClass:"um-calendar__title"},[_vm._v(_vm._s(month)+" "+_vm._s(_vm.year))]),_vm._v(" "),_c('div',{staticClass:"um-calendar__body"},[_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Su")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Mo")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Tu")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("We")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Th")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Fr")]),_vm._v(" "),_c('strong',{staticClass:"um-calendar__weekday"},[_vm._v("Sa")]),_vm._v(" "),_vm._l((_vm.getDays(index)),function({ number, url, isFuture }){return _c(url ? 'a' : 'span',{key:`${_vm.year}_${month}_${index}_${Math.random() * _vm.year}`,tag:"component",staticClass:"um-calendar__day",class:{
                'um-calendar__day--future': isFuture
              },attrs:{"href":url,"role":url ? 'link' : false},domProps:{"textContent":_vm._s(number)}})})],2)])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }